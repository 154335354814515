import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // openDialogEdit: false,
  openDialogView: false,
  openSnackBar: false,
};

/* eslint-disable no-param-reassign */
const deleteSlice = createSlice({
  name: "delete",
  initialState,
  reducers: {
    // openDialogEdit: (state) => {
    //   state.openDialogEdit = true;
    // },
    // closeDialogEdit: (state) => {
    //   state.openDialogEdit = false;
    // },
    openSnackBar: (state) => {
      state.openSnackBar = true;
    },
    closeSnackBar: (state) => {
      state.openSnackBar = false;
    },
    openDialogView: (state) => {
      state.openDialogView = true;
    },
    closeDialogView: (state) => {
      state.openDialogView = false;
    },
  },
});
/* eslint-enable no-param-reassign */

export const { openDialogView, closeDialogView, openSnackBar, closeSnackBar } = deleteSlice.actions;

export default deleteSlice.reducer;
