/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";

// react-router components
// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
// import PropTypes from "prop-types";

// @mui material components
// import Container from "@mui/material/Container";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
// import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink";
// import DefaultNavbarMobile from "examples/Navbars/DefaultNavbar/DefaultNavbarMobile";

// Material Dashboard 2 React base styles
// import breakpoints from "assets/theme/base/breakpoints";

// Material Dashboard 2 React context
// import { useMaterialUIController } from "context";
// import bgImage from "assets/images/logo-light.svg";
// import brandDark from "assets/images/logo-dark.svg";
// import brandWhite from "assets/images/logo-light.svg";
// import logoKKMdark from "assets/images/KKM-dark.svg";
// import logoKKMlight from "assets/images/KKM-light.svg";
import logo from "assets/images/logo.svg";
// import jataMalaysia from "assets/images/Malaysia.svg";

function DefaultNavbar() {
  // const [controller] = useMaterialUIController();
  // const { darkMode } = controller;

  // const [mobileNavbar, setMobileNavbar] = useState(false);
  // const [mobileView, setMobileView] = useState(false);

  // const openMobileNavbar = ({ currentTarget }) => setMobileNavbar(currentTarget.parentNode);
  // const closeMobileNavbar = () => setMobileNavbar(false);

  // useEffect(() => {
  //   // A function that sets the display state for the DefaultNavbarMobile.
  //   function displayMobileNavbar() {
  //     if (window.innerWidth < breakpoints.values.lg) {
  //       setMobileView(true);
  //       setMobileNavbar(false);
  //     } else {
  //       setMobileView(false);
  //       setMobileNavbar(false);
  //     }
  //   }

  //   /**
  //    The event listener that's calling the displayMobileNavbar function when
  //    resizing the window.
  //   */
  //   window.addEventListener("resize", displayMobileNavbar);

  //   // Call the displayMobileNavbar function to set the state with the initial value.
  //   displayMobileNavbar();

  //   // Remove event listener on cleanup
  //   return () => window.removeEventListener("resize", displayMobileNavbar);
  // }, []);

  return (
    <>
      <MDBox
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        component="a"
        href="https://fastlanedynamic.com"
        target="_blank"
        // py={transparent ? 1.5 : 0.75}
        lineHeight={1}
      >
        <MDBox sx={{ mb: 1 }} component="img" src={logo} alt="Brand" width="5rem" />
        <MDTypography textAlign="center" fontWeight="bold" color="white">
          Fastlane Dynamic SelfCare
        </MDTypography>
      </MDBox>
      {/* {mobileView && <DefaultNavbarMobile open={mobileNavbar} close={closeMobileNavbar} />} */}
    </>
  );
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  // transparent: false,
  // light: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  // transparent: PropTypes.bool,
  // light: PropTypes.bool,
};

export default DefaultNavbar;
