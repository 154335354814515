/* eslint-disable import/no-unresolved */
import { useEffect, useState } from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
// import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import { Card, Snackbar, Alert } from "@mui/material";

export default function CheckoutForm() {
  // const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  // console.log(stripe);
  // console.log(elements);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState(null);
  // console.log(message);
  const [isProcessing, setIsProcessing] = useState(false);
  const [snackbarColor, setSnackbarColor] = useState(null);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (message) {
      setOpenSnackbar(true);
    }
  }, [message]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}`,
      },

      redirect: "if_required",
    });

    // if (error) {
    //   setSnackbarColor("error");
    //   setMessage(error.message);
    // } else

    if (paymentIntent && paymentIntent.status === "succeeded") {
      setSnackbarColor("success");
      setMessage(`Payment status: ${paymentIntent.status} 🎉`);
    }

    // else {
    //   setMessage("Unexpected state");
    // }

    // if (error.type === "card_error" || error.type === "validation_error") {
    //   setMessage(error.message);
    // } else {
    //   setMessage("An unexpected error occured.");
    // }

    setIsProcessing(false);

    console.log(error);
  };

  const vertical = "top";
  const horizontal = "center";
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
        open={openSnackbar}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={snackbarColor} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Card
        sx={{
          paddingY: { xs: "20px", sm: "20px" },
          paddingX: { xs: "15px", sm: "20px" },
          marginBottom: "20px",
          marginTop: "20px",
        }}
        type="form"
        id="payment-form"
        onSubmit={handleSubmit}
      >
        <form id="payment-form" onSubmit={handleSubmit}>
          <PaymentElement id="payment-element" />
          <MDButton
            sx={{ marginTop: "20px" }}
            type="submit"
            variant="contained"
            color="success"
            disabled={
              isProcessing || !stripe || !elements || message === "Payment status: succeeded 🎉"
            }
            id="submit"
            onClick={() => {
              setOpenSnackbar(false);
              setMessage(null);
            }}
          >
            {isProcessing ? "Processing ... " : "Bayar"}
          </MDButton>
          {/* Show any error or success messages */}
          {/* {message && <div id="payment-message">{message}</div>} */}

          {/* {message === "Payment status: succeeded 🎉" && (
            <MDButton
              onClick={() => {
                setMessage(null);
                navigate("/");
              }}
            >
              Back to home page
            </MDButton>
          )} */}
        </form>
      </Card>
    </>
  );
}
