import { useEffect, useState } from "react";
// import { onAuthStateChanged } from "firebase/auth";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import {
  List,
  Divider,
  Link,
  Icon,
  Accordion,
  AccordionSummary,
  // AccordionDetails,
  styled,
} from "@mui/material";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
// import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
// import { auth } from "../../firebase-config";

const MAccordion = styled((props) => <Accordion disableGutters elevation={0} square {...props} />)(
  () => ({
    "&:not(:last-child)": {
      borderTop: 0,
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    backgroundColor: "transparent",
  })
);

const MAccordionSummary = styled(({ ...props }) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ iconcolor, theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper": { color: iconcolor },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    color: iconcolor,
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

// const MAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
//   padding: theme.spacing(2),
// }));

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;

  let textColor = "white";
  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "test";
  }

  let iconColor = "white";
  if (transparentSidenav && darkMode) {
    iconColor = "white";
  } else if (whiteSidenav && darkMode) {
    iconColor = "dark";
  } else if (whiteSidenav && !darkMode) {
    iconColor = "dark";
  } else if (transparentSidenav && !darkMode) {
    iconColor = "dark";
  }

  const [expandedPanels, setExpandedPanels] = useState({
    billings: false,
    "radiation-devices": false,
    perlesenan: false,
  });

  const handleChange = (panel) => (event, newExpanded) => {
    setExpandedPanels({
      ...expandedPanels,
      // [panel]: newExpanded ? panel : false, // old code
      [panel]: Boolean(newExpanded),
    });
  };
  // const [hosp, setHosp] = useState("");
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  // useEffect(() => {
  //   const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       // User is logged in
  //       if (user.email === "nuclearmedikn@moh.gov.my") {
  //         setHosp("Institut Kanser Negara");
  //       } else if (user.email === "nuclearmedhkl@moh.gov.my") {
  //         setHosp("Hospital Kuala Lumpur");
  //       } else if (user.email === "demo@myspm.com") {
  //         setHosp("Demo Profile");
  //       } else if (user.email === "nuclearmedhpp@moh.gov.my") {
  //         setHosp("Hospital Pulau Pinang");
  //       } else if (user.email === "nuclearmedhsa@moh.gov.my") {
  //         setHosp("Hospital Sultanah Aminah");
  //       } else if (user.email === "nuclearmedhus@moh.gov.my") {
  //         setHosp("Hospital Umum Sarawak");
  //       } else if (user.email === "nuclearmedhwkks@moh.gov.my") {
  //         setHosp("Hospital Wanita & Kanak-Kanak Sabah");
  //       } else if (user.email === "aimifirdhausshafie@gmail.com") {
  //         setHosp("Limbo");
  //       }
  //     }
  //   });

  //   return () => unsubscribeAuth(); // Unsubscribe from the auth state changes
  // }, []);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route, anak, panel }) => {
      let returnValue;

      if (type === "accordion") {
        returnValue = (
          <MAccordion
            // sx={{
            //   backgroundColor: "inherit",
            //   color: textColor,
            // }}
            disabled={panel === "pendakwaan" || panel === "pekerja-sinaran"}
            TransitionProps={{ unmountOnExit: true }}
            expanded={expandedPanels[panel]}
            onChange={handleChange(panel)}
            key={panel}
          >
            <MAccordionSummary
              iconcolor={iconColor}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <MDTypography color={textColor} variant="button">
                {title}
              </MDTypography>
            </MAccordionSummary>
            {anak.map((e) => (
              <NavLink key={e.key} to={e.route}>
                <SidenavCollapse name={e.name} icon={e.icon} active={e.key === collapseName} />
              </NavLink>
            ))}
          </MAccordion>
        );
      }

      if (type === "collapse") {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <NavLink key={key} to={route}>
            <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
          </NavLink>
        );
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox
          component={NavLink}
          to="/"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <MDBox display="flex" flexDirection="column" alignItems="center">
            <MDBox component="img" src={brand} alt="Brand" width="5rem" sx={{ mb: 2 }} />
            <MDTypography color={textColor} variant="h6" sx={{ mb: 1 }}>
              Fastlane Dynamic SelfCare
            </MDTypography>
            {/* <MDTypography color={textColor} variant="caption" sx={{ mb: 1 }}>
              Bahagian Kawalselia Radiasi Perubatan
            </MDTypography>
            <MDTypography color={textColor} variant="caption">
              Kementerian Kesihatan Malaysia
            </MDTypography> */}
          </MDBox>
          {/*   <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
             <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography> 
          </MDBox> */}
        </MDBox>
      </MDBox>
      <MDBox style={{ display: "flex", justifyContent: "center" }}>
        {/* <MDTypography
          style={{ display: "inline-block", whiteSpace: "pre-line" }}
          variant="body2"
          textAlign="center"
          color={textColor}
        >
          {hosp}
        </MDTypography> */}
      </MDBox>

      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  // brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
