import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  Button,
  Backdrop,
  CircularProgress,
  // Skeleton,
} from "@mui/material";
import { UserAuth } from "context/AuthContext";
import { UserContext } from "context/UserContext";
// import MDButton from "components/MDButton";
import MDTypography from "../../components/MDTypography";
import MDBox from "../../components/MDBox";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
// import Footer from "../../examples/Footer";
import OnlineStatusSnackbar from "../onlineStatusSnackbar/onlineStatusSnackbar";

function PricingList() {
  const { user } = UserAuth();
  const { customers } = useContext(UserContext);
  const custId = customers.filter((cust) => cust.email === user?.email)[0]?.stripeId;
  console.log(custId);
  // const name = user?.displayName?.replace(/\s*\(.*?\)\s*/g, "");
  // const email = user?.email;
  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState("");
  console.log(clientSecret);
  const [newPrices, setNewPrices] = useState([]);
  console.log(newPrices);
  const [backdropOpen, setBackDropOpen] = useState(false);
  // const [newCustomer, setNewCustomer] = useState("");
  // console.log(newCustomer);
  const [newCustomerList, setNewCustomersList] = useState(null);
  console.log(newCustomerList);
  const [newSubscriptionList, setNewSubscriptionList] = useState([]);
  console.log(newSubscriptionList);

  useEffect(() => {
    const fetchCustomers = async () => {
      const { data } = await fetch(
        `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/retrieveCustomer`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: user?.email,
          }),
        }
      ).then((r) => r.json());
      setNewCustomersList(data);
    };

    if (user?.email) {
      fetchCustomers();
    }
  }, [user?.email]);

  useEffect(() => {
    const fetchSubscription = async () => {
      const { data } = await fetch(
        `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/retrieveSubscription`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            customer: newCustomerList[0]?.id,
          }),
        }
      ).then((r) => r.json());
      setNewSubscriptionList(data);
    };

    if (newCustomerList !== null && newCustomerList[0]?.id) {
      fetchSubscription();
    }
  }, [newCustomerList !== null && newCustomerList[0]?.id]);

  useEffect(() => {
    document.title = "Fastlane Dynamic SelfCare - Price List";

    const fetchPrices = async () => {
      const { prices } = await fetch(`${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/config`).then(
        (r) => r.json()
      );
      setNewPrices(prices);
    };
    fetchPrices();
  }, []);

  // const onClickUpdate = async () => {
  //   try {
  //     const { customer } = await fetch(
  //       `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/updateCustomer`,
  //       {
  //         method: "POST",
  //         headers: { "Content-Type": "application/json" },
  //         body: JSON.stringify({
  //           name,
  //           customerId: newCustomerList?.id,
  //         }),
  //       }
  //     ).then((res) => res.json());
  //     setNewCustomer(customer);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // const onClickCancelSubscription = async () => {
  //   try {
  //     const subscription = await fetch(
  //       `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/cancelSubscription`,
  //       {
  //         method: "POST",
  //         headers: { "Content-Type": "application/json" },
  //         body: JSON.stringify({
  //           subscriptionId: newSubscriptionList[0]?.id,
  //         }),
  //       }
  //     ).then((res) => res.json());
  //     setNewSubscriptionList(subscription);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // const onClickRegister = async () => {
  //   try {
  //     const { customer } = await fetch(
  //       `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/createCustomer`,
  //       {
  //         method: "POST",
  //         headers: { "Content-Type": "application/json" },
  //         body: JSON.stringify({
  //           name,
  //           email,
  //         }),
  //       }
  //     ).then((res) => res.json());
  //     setNewCustomer(customer);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // const createPaymentIntent = async (unitAmount) => {
  //   setBackDropOpen(true);
  //   try {
  //     fetch(`${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/createPaymentIntent`, {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({ amount: unitAmount }),
  //     })
  //       .then((res) => res.json())
  //       .then((data) => {
  //         setClientSecret(data.clientSecret);
  //       });
  //     setBackDropOpen(false);
  //   } catch (error) {
  //     console.log(error);
  //     setBackDropOpen(false);
  //   }
  // };

  const createSubscription = async (priceId, customerId) => {
    setBackDropOpen(true);
    try {
      fetch(`${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/createSubscription`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ priceId, customerId }),
      })
        .then((res) => res.json())
        .then((data) => {
          setClientSecret(data.clientSecret);
        });
      setBackDropOpen(false);
    } catch (error) {
      console.log(error);
      setBackDropOpen(false);
    }
  };

  useEffect(() => {
    if (clientSecret) {
      navigate("/pembaharuan-lesen/pembayaran", { state: clientSecret });
    }
  }, [clientSecret]);

  return (
    <DashboardLayout>
      <OnlineStatusSnackbar />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DashboardNavbar />
      <MDBox
        py={3}
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent={{ xs: "space-evenly" }}
        alignItems={{ xs: "center" }}
      >
        {
          // newCustomer.length !== 0 &&
          newPrices.length !== 0 &&
            // ? (
            //   <Skeleton
            //     variant="rounded"
            //     width="11rem"
            //     height="10rem"
            //     sx={{ borderRadius: "0.8rem" }}
            //   />
            // ) :
            newPrices.map((price) => (
              <Card key={price.id} sx={{ maxWidth: 345, marginBottom: { xs: "20px" } }}>
                <CardActionArea>
                  <CardContent>
                    <MDTypography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{ marginTop: "20px" }}
                    >
                      {price.product.name}
                    </MDTypography>
                    <MDTypography variant="body2" color="text">
                      RM{(price.unit_amount / 100).toFixed(2)} / bulan
                    </MDTypography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button
                    disabled={backdropOpen}
                    size="small"
                    color="primary"
                    onClick={() => createSubscription(price.id, custId)}
                  >
                    Pilih
                  </Button>
                </CardActions>
              </Card>
            ))
        }
      </MDBox>
      {/* <MDBox display="flex" justifyContent="center" sx={{ mb: 8 }}>
        <MDTypography>
          Tempoh langganan premium anda bermula daripada tarikh{" "}
          {
            new Date(newSubscriptionList[0]?.current_period_start * 1000)
              .toDateString()
              .split(" ")[2]
          }{" "}
          {
            new Date(newSubscriptionList[0]?.current_period_start * 1000)
              .toDateString()
              .split(" ")[1]
          }{" "}
          {
            new Date(newSubscriptionList[0]?.current_period_start * 1000)
              .toDateString()
              .split(" ")[3]
          }
          {" sehingga "}
          {
            new Date(newSubscriptionList[0]?.current_period_end * 1000).toDateString().split(" ")[2]
          }{" "}
          {new Date(newSubscriptionList[0]?.current_period_end * 1000).toDateString().split(" ")[1]}{" "}
          {new Date(newSubscriptionList[0]?.current_period_end * 1000).toDateString().split(" ")[3]}
        </MDTypography>
      </MDBox>
      <MDBox sx={{ mb: 3 }} display="flex" justifyContent="space-around">
        <MDButton
          disabled={newCustomer.length !== 0 || newCustomerList?.name !== null}
          color="info"
          onClick={() => onClickUpdate()}
        >
          Daftar Langganan Premium
        </MDButton>

        <MDButton
          disabled={newSubscriptionList[0]?.status !== "active"}
          color="error"
          onClick={() => onClickCancelSubscription()}
        >
          Batal Langganan Premium
        </MDButton>
      </MDBox> */}
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default PricingList;
