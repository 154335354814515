import {
  Card,
  Grid,
  MenuItem,
  Snackbar,
  Alert,
  Collapse,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
// import Footer from "examples/Footer";
import { useDispatch, useSelector } from "react-redux";
import { circularProgressOpen, circularProgressClose } from "slices/idDeleteSlice";
import { UserAuth } from "context/AuthContext";
import OnlineStatusSnackbar from "../onlineStatusSnackbar/onlineStatusSnackbar";
import { db } from "../../firebase-config";

function Register() {
  const navigate = useNavigate();
  const { user } = UserAuth();
  // console.log(user);
  const usersCollectionRef = collection(db, "machines");
  useEffect(() => {
    document.title = "Pendaftaran - eRadia";
  }, []);
  const dispatch = useDispatch();
  const { circularProgressStatus } = useSelector((state) => state.idDelete);

  const [openAlert, setOpenAlert] = useState(false);
  const [state, setState] = useState({
    openToast: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, openToast } = state;

  const handleToastOpen = () => {
    setState({ openToast: true, vertical: "top", horizontal: "center" });
  };

  const handleToastClose = () => {
    setState({ ...state, openToast: false });
  };

  const [newModel, setNewModel] = useState("");
  const [newSektor, setNewSektor] = useState("");
  const [error, setError] = useState("");
  const [negeri, setNegeri] = useState("");
  // const [kategori, setKategori] = useState("");
  const [jenama, setJenama] = useState("");
  const [tarikhPembelian, setTarikhPembelian] = useState(new Date().setHours(0, 0, 0, 0));
  const tarikhPembelianTimeStamp = Timestamp.fromDate(
    new Date(new Date(tarikhPembelian).setHours(0, 0, 0, 0))
  );
  const createdTime = Timestamp.fromDate(new Date());

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await addDoc(usersCollectionRef, {
        negeri,
        jenama,
        sektor: newSektor,
        model: newModel,
        createdBy: user?.email,
        createdTime,
        tarikhPembelianTimeStamp,
        // kategori,
      });

      dispatch(circularProgressClose());
      setNewSektor("");
      setNegeri("");
      setNewModel("");
      navigate("/radas-penyinaran/senarai");
    } catch (err) {
      setError(err.message);
      handleToastOpen();
    }
  };

  const height = { height: 44 };

  const statesArray = [
    { name: "Please Select" },
    { name: "Kelantan" },
    { name: "Perlis" },
    { name: "Kedah" },
    { name: "Pulau Pinang" },
    { name: "Perak" },
    { name: "Selangor" },
    { name: "Kuala Lumpur" },
    { name: "Putrajaya" },
    { name: "Negeri Sembilan" },
    { name: "Melaka" },
    { name: "Johor" },
    { name: "Pahang" },
    { name: "Terengganu" },
    { name: "Sarawak" },
    { name: "Sabah" },
    { name: "Labuan" },
  ];

  const sektorArray = [{ name: "Please Select" }, { name: "Kerajaan" }, { name: "Swasta" }];

  const jenamaArray = [
    { name: "Please Select" },
    { name: "Toshiba" },
    { name: "Elekta" },
    { name: "Varian" },
    { name: "Samsung" },
    { name: "Siemens" },
    { name: "GE" },
    { name: "Mindray" },
    { name: "Shimadzu" },
    { name: "Philips" },
  ];

  const modelsArray = [
    { name: "Please Select" },
    { name: "KX-80S" },
    { name: "GM60A-32S" },
    { name: "MobiEye 700" },
  ];

  return (
    <DashboardLayout>
      <OnlineStatusSnackbar />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={circularProgressStatus}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        autoHideDuration={4000}
        anchorOrigin={{ vertical, horizontal }}
        open={openToast}
        onClose={handleToastClose}
        message="I love snacks"
        key={vertical + horizontal}
      >
        <Alert onClose={handleToastClose} severity="error">
          <MDTypography variant="h6" fontWeight="medium" color="error">
            {error}
          </MDTypography>
        </Alert>
      </Snackbar>
      <Collapse in={openAlert}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2, mx: 3, mt: 2 }}
        >
          <MDTypography variant="h6" fontWeight="medium" color="error">
            {error}
          </MDTypography>
        </Alert>
      </Collapse>
      <DashboardNavbar />
      <MDBox py={3}>
        <Card component="form" role="form" onSubmit={handleSubmit}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox pt={4} px={3} sx={{ pb: { xs: 0, sm: 0, md: 3, lg: 3 } }}>
                <MDBox>
                  <MDBox mb={2}>
                    <FormControl required fullWidth>
                      <InputLabel id="job-select-label">Negeri</InputLabel>
                      <Select
                        labelId="subject-select-label"
                        id="subject-select"
                        value={negeri}
                        label="Negeri"
                        onChange={(e) => setNegeri(e.target.value)}
                        style={height}
                      >
                        {statesArray.map((negeris) => {
                          if (negeris.name === "Please Select") {
                            return (
                              <MenuItem key={negeris.name} value="">
                                <em>Pilih negeri</em>
                              </MenuItem>
                            );
                          }

                          return (
                            <MenuItem key={negeris.name} value={negeris.name}>
                              {negeris.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </MDBox>
                  <MDBox mb={2}>
                    <FormControl required fullWidth>
                      <InputLabel id="job-select-label">Sektor</InputLabel>
                      <Select
                        labelId="subject-select-label"
                        id="subject-select"
                        value={newSektor}
                        label="Sektor"
                        onChange={(e) => setNewSektor(e.target.value)}
                        style={height}
                      >
                        {sektorArray.map((sektor) => {
                          if (sektor.name === "Please Select") {
                            return (
                              <MenuItem key={sektor.name} value="">
                                <em>Pilih sektor</em>
                              </MenuItem>
                            );
                          }

                          return (
                            <MenuItem key={sektor.name} value={sektor.name}>
                              {sektor.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </MDBox>
                  {/*  <MDBox mb={2}>
                    <MDInput
                      id="kategori"
                      value={kategori}
                      required
                      style={height}
                      type="text"
                      label="Kategori"
                      fullWidth
                      onChange={(e) => {
                        setKategori(e.target.value);
                      }}
                    >
                      <MDTypography />
                    </MDInput>
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      value="b"
                      required
                      type="text"
                      label="Question"
                      // onChange={(e) => setNewQuestion(e.target.value)}
                      // multiline
                      // rows={4}
                      fullWidth
                    />
                  </MDBox> */}
                </MDBox>
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <MDBox pb={3} px={3} sx={{ pt: { xs: 0, sm: 0, md: 4, lg: 4 } }}>
                <MDBox>
                  <MDBox mb={2}>
                    <FormControl required fullWidth>
                      <InputLabel id="job-select-label">Jenama</InputLabel>
                      <Select
                        labelId="subject-select-label"
                        id="subject-select"
                        value={jenama}
                        label="Sektor"
                        onChange={(e) => setJenama(e.target.value)}
                        style={height}
                      >
                        {jenamaArray.map((model) => {
                          if (model.name === "Please Select") {
                            return (
                              <MenuItem key={model.name} value="">
                                <em>Pilih jenama</em>
                              </MenuItem>
                            );
                          }

                          return (
                            <MenuItem key={model.name} value={model.name}>
                              {model.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </MDBox>
                  <MDBox mb={2}>
                    <FormControl required fullWidth>
                      <InputLabel id="job-select-label">Model</InputLabel>
                      <Select
                        labelId="subject-select-label"
                        id="subject-select"
                        value={newModel}
                        label="Sektor"
                        onChange={(e) => setNewModel(e.target.value)}
                        style={height}
                      >
                        {modelsArray.map((model) => {
                          if (model.name === "Please Select") {
                            return (
                              <MenuItem key={model.name} value="">
                                <em>Pilih model</em>
                              </MenuItem>
                            );
                          }

                          return (
                            <MenuItem key={model.name} value={model.name}>
                              {model.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      style={height}
                      InputLabelProps={{ shrink: true }}
                      type="date"
                      label="Tarikh pembelian / tarikh penerimaan"
                      fullWidth
                      onChange={(e) => setTarikhPembelian(e.target.value)}
                    />
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
          <MDBox mt={3} mb={3} ml={3} mr={3}>
            <MDButton
              disabled={newSektor === "" || newModel === "" || negeri === ""}
              sx={{ borderRadius: "23px" }}
              variant="gradient"
              color="info"
              fullWidth
              type="submit"
              onClick={() => {
                dispatch(circularProgressOpen());
              }}
            >
              Daftar
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Register;
