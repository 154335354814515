import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Backdrop, CircularProgress } from "@mui/material";
import { useMaterialUIController } from "../../context";
import CheckoutForm from "./CheckoutForm";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
// import Footer from "../../examples/Footer";
import OnlineStatusSnackbar from "../onlineStatusSnackbar/onlineStatusSnackbar";

function Payment() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  useEffect(() => {
    document.title = "Fastlane Dynamic SelfCare - Checkout";
  }, []);

  const { state } = useLocation();
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret] = useState(state);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/config`).then(async (r) => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  const appearance = {
    theme: darkMode ? "night" : "flat",
    labels: darkMode ? "floating" : "",
  };

  return (
    <DashboardLayout>
      <OnlineStatusSnackbar />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!stripePromise}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DashboardNavbar />
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
          <CheckoutForm />
        </Elements>
      )}
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Payment;
