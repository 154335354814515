import React from "react";
// import { createRoot } from "react-dom/client";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

// Material Dashboard 2 React Context Provider
import { Provider } from "react-redux";
import { SplashProvider } from "SplashProvider";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
import { MaterialUIControllerProvider } from "./context";
import { UserProvider } from "./context/UserContext";
import App from "./App";
import { store } from "./store";

// const container = document.getElementById("root");
// const root = createRoot(container);

// const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

ReactDOM.render(
  <SplashProvider>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <Provider store={store}>
          <UserProvider>
            <App />
          </UserProvider>
        </Provider>
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </SplashProvider>,
  document.getElementById("root")
);
