/* eslint-disable import/no-unresolved */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
// import Tables from "layouts/tables";
// import Billing from "layouts/billing";
// import Notifications from "layouts/notifications";
// import Profile from "layouts/profile";
// import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import Register from "layouts/register";
import ProtectedRoute from "ProtectedRoute";
import PricingList from "layouts/pricing-lists";
import Tables from "layouts/tables";
// import Subscribe from "layouts/subscription";
// import Math2020 from "layouts/mathematics/2020";
// import PersonnelPreAnalysis from "layouts/personnel-dosimeter/pre-analysis";
// import PersonnelPostAnalysis from "layouts/personnel-dosimeter/post-analysis";
// import PersonnelAnalysisResults from "layouts/personnel-dosimeter/analysis-results";
// import ExtremityAnalysis from "layouts/extremity-dosimeter/pre-analysis";
// import ExtremityPostAnalysis from "layouts/extremity-dosimeter/post-analysis";
// import ExtremityAnalysisResults from "layouts/extremity-dosimeter/analysis-results";

const routes = [
  {
    type: "collapse",
    name: "Home",
    key: "",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/",
    component: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  // {
  //   type: "divider",
  //   key: "div0",
  // },
  // {
  //   type: "accordion",
  //   name: "Test",
  //   title: "Radiation Workers",
  //   key: "test",
  //   panel: "radiation-workers",
  //   anak: [
  //     {
  //       name: "Profiles Table",
  //       icon: <Icon fontSize="small">table_view</Icon>,
  //       key: "radiation-workers/profiles-table",
  //       route: "/radiation-workers/profiles-table",
  //       component: (
  //         <ProtectedRoute>
  //           <Tables />
  //         </ProtectedRoute>
  //       ),
  //     },
  //     {
  //       name: "Register",
  //       icon: <Icon fontSize="small">receipt_long</Icon>,
  //       key: "radiation-workers/register",
  //       route: "/radiation-workers/register",
  //       component: (
  //         <ProtectedRoute>
  //           <Register />
  //         </ProtectedRoute>
  //       ),
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Subscribe",
  //   key: "subscribe",
  //   icon: <Icon fontSize="small">dashboard</Icon>,
  //   route: "/subscribe",
  //   component: (
  //     <ProtectedRoute>
  //       <Subscribe />
  //     </ProtectedRoute>
  //   ),
  // },
  // {
  //   type: "accordion",
  //   name: "Test",
  //   title: "Mathematics",
  //   key: "test",
  //   panel: "mathematics",
  //   anak: [
  //     {
  //       name: "2020",
  //       icon: <Icon fontSize="small">table_view</Icon>,
  //       key: "",
  //       route: "/",
  //       component: (
  //         <ProtectedRoute>
  //           <Math2020 />
  //         </ProtectedRoute>
  //       ),
  //     },
  //   ],
  // },
  {
    type: "divider",
    key: "div1",
  },
  // {
  //   type: "collapse",
  //   name: "Radiation Workers",
  //   key: "radiation-workers",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/radiation-workers",
  //   component: (
  //     <ProtectedRoute>
  //       <Tables />
  //     </ProtectedRoute>
  //   ),
  // },
  // {
  //   type: "collapse",
  //   name: "Register",
  //   key: "register",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/register",
  //   component: (
  //     <ProtectedRoute>
  //       <Register />
  //     </ProtectedRoute>
  //   ),
  // },
  {
    type: "accordion",
    name: "Radiation Devices",
    title: "Radiation Devices",
    key: "radiation-devices",
    panel: "radiation-devices",
    anak: [
      {
        name: "Daftar",
        key: "radas-penyinaran/daftar",
        icon: <Icon fontSize="small">assignment</Icon>,
        route: "/radas-penyinaran/daftar",
        component: (
          <ProtectedRoute>
            <Register />
          </ProtectedRoute>
        ),
      },
      {
        name: "Senarai",
        key: "radas-penyinaran/senarai",
        icon: <Icon fontSize="small">view_list</Icon>,
        route: "/radas-penyinaran/senarai",
        component: (
          <ProtectedRoute>
            <Tables />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    type: "divider",
    key: "div3",
  },
  {
    type: "accordion",
    name: "Billings",
    title: "Billings",
    key: "billings",
    panel: "billings",
    anak: [
      {
        name: "Payment",
        icon: <Icon fontSize="small">shopping_cart</Icon>,
        key: "billings/payment",
        route: "/billings/payment",
        component: (
          <ProtectedRoute>
            <PricingList />
          </ProtectedRoute>
        ),
      },
    ],
  },

  // {
  //   type: "divider",
  //   key: "div4",
  // },
  // {
  //   type: "accordion",
  //   name: "Pekerja Sinaran",
  //   title: "Pekerja Sinaran",
  //   key: "pekerja-sinaran",
  //   panel: "pekerja-sinaran",
  //   anak: [
  //     {
  //       name: "Daftar",
  //       key: "radas-penyinaran/daftar",
  //       icon: <Icon fontSize="small">assignment</Icon>,
  //       route: "/radas-penyinaran/daftar",
  //       component: (
  //         <ProtectedRoute>
  //           <Register />
  //         </ProtectedRoute>
  //       ),
  //     },
  //     {
  //       name: "Senarai",
  //       key: "radas-penyinaran/senarai",
  //       icon: <Icon fontSize="small">view_list</Icon>,
  //       route: "/radas-penyinaran/senarai",
  //       component: (
  //         <ProtectedRoute>
  //           <Tables />
  //         </ProtectedRoute>
  //       ),
  //     },
  //   ],
  // },
  // {
  //   type: "divider",
  //   key: "div5",
  // },
  // {
  //   type: "accordion",
  //   name: "Pendakwaan",
  //   title: "Pendakwaan",
  //   key: "pendakwaan",
  //   panel: "pendakwaan",
  //   anak: [
  //     {
  //       name: "Daftar",
  //       key: "radas-penyinaran/daftar",
  //       icon: <Icon fontSize="small">assignment</Icon>,
  //       route: "/radas-penyinaran/daftar",
  //       component: (
  //         <ProtectedRoute>
  //           <Register />
  //         </ProtectedRoute>
  //       ),
  //     },
  //     {
  //       name: "Senarai",
  //       key: "radas-penyinaran/senarai",
  //       icon: <Icon fontSize="small">view_list</Icon>,
  //       route: "/radas-penyinaran/senarai",
  //       component: (
  //         <ProtectedRoute>
  //           <Tables />
  //         </ProtectedRoute>
  //       ),
  //     },
  //   ],
  // },
  // {
  //   type: "divider",
  //   key: "div6",
  // },
  // {
  //   type: "accordion",
  //   name: "Test",
  //   title: "Extremity Dosimeter",
  //   key: "test",
  //   panel: "extremity-dosimeter",
  //   anak: [
  //     {
  //       name: "Dosimeter Assignment",
  //       key: "extremity-dosimeter/dosimeter-assignment",
  //       icon: <Icon fontSize="small">assignment</Icon>,
  //       route: "/extremity-dosimeter/dosimeter-assignment",
  //       component: (
  //         <ProtectedRoute>
  //           <ExtremityAnalysis />
  //         </ProtectedRoute>
  //       ),
  //     },
  //     {
  //       name: "Dosimeter Analysis",
  //       key: "extremity-dosimeter/dosimeter-analysis",
  //       icon: <Icon fontSize="small">biotech</Icon>,
  //       route: "/extremity-dosimeter/dosimeter-analysis",
  //       component: (
  //         <ProtectedRoute>
  //           <ExtremityPostAnalysis />
  //         </ProtectedRoute>
  //       ),
  //     },
  //     {
  //       name: "Analysis Results",
  //       key: "extremity-dosimeter/analysis-results",
  //       icon: <Icon fontSize="small">format_list_numbered</Icon>,
  //       route: "/extremity-dosimeter/analysis-results",
  //       component: (
  //         <ProtectedRoute>
  //           <ExtremityAnalysisResults />
  //         </ProtectedRoute>
  //       ),
  //     },
  //   ],
  // },
  // {
  //   type: "divider",
  //   key: "div3",
  // },
  // {
  //   type: "accordion",
  //   name: "Test",
  //   title: "Environmental Dosimeter",
  //   key: "test",
  //   panel: "environmental-dosimeter",
  //   anak: [
  //     {
  //       name: "Dosimeter Assignment",
  //       key: "extremity-dosimeter/dosimeter-assignment",
  //       icon: <Icon fontSize="small">assignment</Icon>,
  //       route: "/extremity-dosimeter/dosimeter-assignment",
  //       component: (
  //         <ProtectedRoute>
  //           <ExtremityAnalysis />
  //         </ProtectedRoute>
  //       ),
  //     },
  //     {
  //       name: "Dosimeter Analysis",
  //       key: "extremity-dosimeter/dosimeter-analysis",
  //       icon: <Icon fontSize="small">biotech</Icon>,
  //       route: "/extremity-dosimeter/dosimeter-analysis",
  //       component: (
  //         <ProtectedRoute>
  //           <ExtremityPostAnalysis />
  //         </ProtectedRoute>
  //       ),
  //     },
  //     {
  //       name: "Analysis Results",
  //       key: "extremity-dosimeter/analysis-results",
  //       icon: <Icon fontSize="small">format_list_numbered</Icon>,
  //       route: "/extremity-dosimeter/analysis-results",
  //       component: (
  //         <ProtectedRoute>
  //           <ExtremityAnalysisResults />
  //         </ProtectedRoute>
  //       ),
  //     },
  //   ],
  // },

  // {
  //   type: "collapse",
  //   name: "Radioactive Purchase",
  //   key: "billing",
  //   icon: <Icon fontSize="small">attach_money</Icon>,
  //   route: "/billing",
  //   component: (
  //     <ProtectedRoute>
  //       <Billing />
  //     </ProtectedRoute>
  //   ),
  // },
  /*
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  */
];

export default routes;
