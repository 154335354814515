import { createContext, useState, useEffect, useMemo } from "react";
import { collection, query, onSnapshot } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { db, auth } from "../firebase-config";

export const UserContext = createContext();

export function UserProvider({ children }) {
  const [machines, setMachines] = useState([]);
  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState("");
  const [customers, setCustomers] = useState([]);
  // console.log(currentUser);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user.displayName);
      } else {
        // User is logged out
        setCurrentUser("");
      }
    });

    return () => unsubscribeAuth(); // Unsubscribe from the auth state changes
  }, []);

  useEffect(() => {
    const q = query(collection(db, "machines"));
    if (currentUser) {
      const unsubscribeUsers = onSnapshot(
        q,
        (snapshot) => {
          setError(null);
          setMachines(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        },
        (e) => {
          setError(e.message);
        }
      );

      return () => unsubscribeUsers(); // Unsubscribe from the users snapshot
    }
    setError(null);

    return undefined;

    // return () => unsubscribeUsers(); // Unsubscribe from the users snapshot
  }, [currentUser, machines.length, error]);

  useEffect(() => {
    const q = query(collection(db, "customers"));

    const unsubscribeCustomers = onSnapshot(q, (snapshot) =>
      setCustomers(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    );

    return () => unsubscribeCustomers();
  }, [currentUser]);

  const memoedMachines = useMemo(
    () => ({
      machines,
      error,
      customers,
    }),
    [machines, error, currentUser, customers]
  );

  return <UserContext.Provider value={memoedMachines}>{children}</UserContext.Provider>;
}
